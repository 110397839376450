<template>
  <div>
    <b-button variant="outline-primary" v-b-modal.customStripModal>Customize Title Strip</b-button>
    <b-modal id="customStripModal" size="xl" title="Customize Title Strip" @ok="onOk()" ok-title="Save">
      <b-row>
        <b-col cols="3">
          <h4>Select Colors</h4>
          <b-form-group id="color-group" label-for="color">
            <b-form-input id="color" v-model.trim="customize.primaryColor" type="color" />
            <b-form-input id="color" v-model.trim="customize.secondaryColor" type="color" />
            <b-form-input id="color" v-model.trim="customize.tertiaryColor" type="color" />
          </b-form-group>
        </b-col>
        <b-col cols="5">
          <h4>Presets</h4>
          <div class="presets">
            <div v-for="(preset, index) in presets" class="preset" :key="index" @click="setPreset(preset)">
              <div class="colorBox" :style="{ backgroundColor: preset.primaryColor }"></div>
              <div class="colorBox" :style="{ backgroundColor: preset.secondaryColor }"></div>
              <div class="colorBox" :style="{ backgroundColor: preset.tertiaryColor }"></div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h4>Select Design</h4>
          <div class="cards">
            <div v-for="(style) in svgData" class="cardRow" :class="{ selected: customize.design === style.id }"
              :key="style.id" @click="() => { customize.design = style.id }">
              <Card artist="Artist" sideA="Side A" sideB="Side B" :customize="{
                primaryColor: customize.primaryColor,
                secondaryColor: customize.secondaryColor,
                tertiaryColor: customize.tertiaryColor,
                design: style.id,
              }" />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import Card from "@/components/45/Card";
import svgData from "@/components/45/svgData.json";

export default {
  name: "StripDesigner",
  props: {
    defaults: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      svgData,
      customize: {
        primaryColor: '#148815',
        secondaryColor: '#b7e6b7',
        tertiaryColor: '#ffffff',
        design: 1,
      },
      presets: [
        {
          primaryColor: '#e62222',
          secondaryColor: '#ffffff',
          tertiaryColor: '#ffffff',
        },
        {
          primaryColor: '#e62222',
          secondaryColor: '#ffe0e0',
          tertiaryColor: '#ffffff',
        },
        {
          primaryColor: '#e62222',
          secondaryColor: '#ffffff',
          tertiaryColor: '#ffe0e0',
        },
        {
          primaryColor: '#148815',
          secondaryColor: '#ffffff',
          tertiaryColor: '#ffffff',
        },
        {
          primaryColor: '#148815',
          secondaryColor: '#b7e6b7',
          tertiaryColor: '#ffffff',
        },
        {
          primaryColor: '#148814',
          secondaryColor: '#ffffff',
          tertiaryColor: '#b7e6b7',
        },
        {
          primaryColor: '#722291',
          secondaryColor: '#ffffff',
          tertiaryColor: '#ffffff',
        },
        {
          primaryColor: '#722291',
          secondaryColor: '#f8efff',
          tertiaryColor: '#ffffff',
        },
        {
          primaryColor: '#722291',
          secondaryColor: '#ffffff',
          tertiaryColor: '#f8efff',
        },
        {
          primaryColor: '#1924b3',
          secondaryColor: '#ffffff',
          tertiaryColor: '#ffffff',
        },
        {
          primaryColor: '#1924b3',
          secondaryColor: '#e9ebfb',
          tertiaryColor: '#ffffff',
        },
        {
          primaryColor: '#1924b3',
          secondaryColor: '#ffffff',
          tertiaryColor: '#e9ebfb',
        },
        {
          primaryColor: '#FAD900',
          secondaryColor: '#ffffff',
          tertiaryColor: '#ffffff',
        },
        {
          primaryColor: '#FAD900',
          secondaryColor: '#fffdf0',
          tertiaryColor: '#ffffff',
        },
        {
          primaryColor: '#FAD900',
          secondaryColor: '#ffffff',
          tertiaryColor: '#fffdf0',
        },
        {
          primaryColor: '#FFA200',
          secondaryColor: '#ffffff',
          tertiaryColor: '#ffffff',
        },
        {
          primaryColor: '#FFA200',
          secondaryColor: '#FFF8EB',
          tertiaryColor: '#ffffff',
        },
        {
          primaryColor: '#FFA200',
          secondaryColor: '#ffffff',
          tertiaryColor: '#FFF8EB',
        },
        {
          primaryColor: '#000000',
          secondaryColor: '#ffffff',
          tertiaryColor: '#ffffff',
        },
        {
          primaryColor: '#000000',
          secondaryColor: '#e1e1e1',
          tertiaryColor: '#ffffff',
        },
        {
          primaryColor: '#000000',
          secondaryColor: '#ffffff',
          tertiaryColor: '#e1e1e1',
        },
        {
          primaryColor: '#ff8a8a',
          secondaryColor: '#ffffff',
          tertiaryColor: '#ffffff',
        },
        {
          primaryColor: '#ff8a8a',
          secondaryColor: '#ffe0e0',
          tertiaryColor: '#ffffff',
        },
        {
          primaryColor: '#ff8a8a',
          secondaryColor: '#ffffff',
          tertiaryColor: '#ffe0e0',
        },
      ]
    };
  },
  methods: {
    onOk() {
      this.$emit('customize', Object.assign({}, this.customize))
    },
    setPreset(preset) {
      this.customize.primaryColor = preset.primaryColor
      this.customize.secondaryColor = preset.secondaryColor
      this.customize.tertiaryColor = preset.tertiaryColor
    }
  },
  mounted() {
    if (this.defaults) {
      this.customize = Object.assign({}, this.defaults);
    } else {
      this.customize = Object.assign({}, this.$store.getters.getUser.meta.defaultTitleStrip);
    }
  },
  components: {
    Card
  },
};
</script>
<style scoped>
.cards {
  display: grid;
  grid-template-columns: repeat(3, 304px);
  grid-gap: 1rem;
}

.cardRow {
  cursor: pointer;
  border: 4px dotted transparent;
  padding: 5px;
}

.cardRow.selected {
  border-color: rgb(0, 0, 0);
}

.presets {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 5px;
}

.preset {
  cursor: pointer;
}

.colorBox {
  width: 16px;
  height: 16px;
  border: 1px solid #000;
  margin: 1px;
  display: inline-block;
}
</style>
