<template>
  <div class="m-4">
    <b-row>
      <b-col>
        <h2 class="mb-4">Layout Studio</h2>
      </b-col>
      <b-col class="text-right"> <b-button variant="primary" @click="showModal()">
          <BIconPlusLg class="mr-1" /> Add Layout
        </b-button>
      </b-col>
    </b-row>
    <AddLayout ref="addLayout" />
    <b-alert variant="warning" :show="!layouts.length" class="m-5">
      You don't have any Layout. Click <b-link @click="showModal()">Add Layout</b-link> to add your first Layout.
    </b-alert>

    <div v-if="layouts.length">
      <b-table striped hover :items="layouts" :fields="fields"
        @row-clicked="(item) => { $router.push({ name: 'designerlayout', params: { id: item._id } }) }">
        <template #cell(edit)="data">
          <b-button variant="primary" size="sm" @click="showModal(data.item._id)" v-b-tooltip.hover
            :title="`Edit ${data.item.title}`">
            <BIconPen />
          </b-button> &nbsp;
          <b-button variant="warning" size="sm" @click="onCloneLayout(data.item._id, data.item.title)" v-b-tooltip.hover
            :title="`Clone ${data.item.title}`">
            <img src="/images/copy-outline-icon.svg" width="14" />
          </b-button> &nbsp;
          <b-button variant="danger" size="sm" @click="onDeleteLayout(data.item._id, data.item.title)" v-b-tooltip.hover
            :title="`Delete ${data.item.title}`">
            <BIconTrash />
          </b-button>
        </template>
        <template #cell(updated_on)="data">
          {{
            new Date(data.item.updated_on).toLocaleDateString("en-us", {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })
          }}
        </template>
        <template #cell(records)="data">
          {{
            data.item.colsPerPanel * data.item.recordsPerColumn * data.item.panels
          }}
        </template>
        <template #cell(created_on)="data">
          {{
            new Date(data.item.created_on).toLocaleDateString("en-us", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })
          }}
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import AddLayout from "@/components/AddLayout";
import { deleteLayout, cloneLayout } from "@/requests";
import { BIconTrash, BIconPen, BIconPlusLg } from "bootstrap-vue";

export default {
  name: "LayoutStudioTable",
  data() {
    return {
      fields: [
        { key: "title", label: "Title", tdClass: "font-weight-bold" },
        { key: "records", label: "Records" },
        { key: "updated_on", label: "Last Updated" },
        { key: "created_on", label: "Created On" },
        {
          key: "edit", label: "",
          thStyle: { width: "175px" },
        },
      ],
    };
  },
  methods: {
    showModal(id) {
      this.$refs.addLayout.open(id);
    },
    onDeleteLayout(id, title) {
      this.$bvModal.msgBoxConfirm(
        this.$createElement('div', { domProps: { innerHTML: `Are you sure you want to delete layout <strong>${title}<strong>?` } })
        , {
          cancelVariant: 'outline-primary',
          okVariant: 'danger',
          title: 'Delete Layout',
        }).then((response) => {
          if (response) {
            deleteLayout(id)
          }
        })

    },
    onCloneLayout(id, title) {
      this.$bvModal.msgBoxConfirm(
        this.$createElement('div', { domProps: { innerHTML: `Are you sure you want to clone layout <strong>${title}<strong>?` } })
        , {
          cancelVariant: 'outline-primary',
          okVariant: 'primary',
          title: 'Clone Layout',
        }).then((response) => {
          if (response) {
            cloneLayout(id)
          }
        })
    },
  },
  components: { AddLayout, BIconTrash, BIconPen, BIconPlusLg },
  computed: {
    layouts() {
      return this.$store.getters.getLayouts;
    },
  },
  mounted() {
    this.$store.dispatch("getLayouts");
  },
};
</script>
