<template>
  <b-modal ref="addLayoutModal" :title="`${edit ? 'Edit' : 'Add'} Layout`" @ok="save" size="xl"
    :ok-title="`${edit ? 'Save Changes' : 'Create Layout'}`">
    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <b-form-group id="title-group" label="Layout Title*:" label-for="title">
              <b-form-input id="title" v-model.trim="layout.title" :placeholder="placeholder" type="text"
                @blur="setValidationTouched('title', $event.target.value)" :state="validateState('title')" required />
              <b-form-invalid-feedback v-if="!$v.layout.title.required && $v.layout.title.$dirty">Title is required
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group id="color-group" label="Color*:" label-for="color">
              <b-form-input id="color" v-model.trim="layout.color" type="color"
                @blur="setValidationTouched('color', $event.target.value)" :state="validateState('color')" required />
              <b-form-invalid-feedback v-if="!$v.layout.color.required && $v.layout.color.$dirty">color is required
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col> <b-form-group id="panels-group" label="Panels*" label-for="panels">
              <b-form-input id="panels" v-model.trim="layout.panels" type="number" min="1" max="20"
                :formatter="panelFormatter" @blur="setValidationTouched('panels', $event.target.value)"
                :state="validateState('panels')" required />
              <b-form-invalid-feedback v-if="!$v.layout.panels.required && $v.layout.panels.$dirty">Panels is required
              </b-form-invalid-feedback>
            </b-form-group></b-col>
          <b-col>
            <b-form-group id="colsPerPanel-group" label="Columns / Panel*" label-for="colsPerPanel">
              <b-form-input id="colsPerPanel" v-model.trim="layout.colsPerPanel" type="number" min="1" max="2"
                maxlength="2" @blur="setValidationTouched('colsPerPanel', $event.target.value)"
                :state="validateState('colsPerPanel')" :formatter="colPerPanelFormatter" required />
              <b-form-invalid-feedback v-if="!$v.layout.colsPerPanel.required && $v.layout.colsPerPanel.$dirty">Columns /
                Panel is required
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="recordsPerColumn-group" label="Records / Column*" label-for="recordsPerColumn">
              <b-form-input id="recordsPerColumn" v-model.trim="layout.recordsPerColumn" type="number" min="1" max="20"
                :formatter="recordsPerColumnFormatter"
                @blur="setValidationTouched('recordsPerColumn', $event.target.value)"
                :state="validateState('recordsPerColumn')" required />
              <b-form-invalid-feedback
                v-if="!$v.layout.recordsPerColumn.required && $v.layout.recordsPerColumn.$dirty">Records / Column is
                required
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <strong>Total Records:</strong> {{ totalRecords }}
          </b-col>
        </b-row>
        <hr />
        <label><strong>Heading for Panels</strong></label>
        <b-row v-for="(index) in parseInt(layout.panels)" :key="index" class="mt-1">
          <b-col sm="1">
            <label :for="'title-' + index">#{{ index }}:</label>
          </b-col>
          <b-col sm="11">
            <b-form-input :id="'title-' + index" v-model.trim="layout.headings[index - 1]" />
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-row>
          <b-col align-self="center" :style="{ display: 'grid' }">
            <div :style="{ display: 'grid', gridTemplateColumns: '1fr 1fr' }">
              <h2>Panel Preview</h2>
              <StripDesigner @customize="onUpdateCustomize" :defaults="layout.titleStrip"
                :style="{ justifySelf: 'end' }" />
            </div>
            <div class="preview text-center">
              <h3 :style="{ backgroundColor: layout.color }">{{ layout.headings[0] || 'Panel 1' }}</h3>
              <div class="previewGrid" :style="{ gridTemplateColumns: `repeat(${layout.colsPerPanel}, 1fr)` }">
                <Card class="titlecard" artist="Aritst" sideA="Side A" sideB="Side B" :customize="layout.titleStrip"
                  v-for="(index) in parseInt(layout.colsPerPanel * layout.recordsPerColumn)" :key="index"></Card>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { addLayout } from "@/requests";
import { required } from 'vuelidate/lib/validators'
import Card from "@/components/45/Card";
import StripDesigner from "@/components/inputs/stripDesigner";


const recordsPerColumn = 5;
const defaultLayout = {
  title: '',
  color: '#2CA8C7',
  panels: 5,
  colsPerPanel: 2,
  recordsPerColumn,
  headings: Array(recordsPerColumn).fill(''),
  titleStrip: null,
};

export default {
  name: "AddLayoutModal",
  data() {
    return {
      edit: false,
      layout: { ...defaultLayout }
    };
  },
  validations: {
    layout: {
      title: {
        required
      },
      panels: {
        required
      },
      color: {
        required
      },
      colsPerPanel: {
        required
      },
      recordsPerColumn: {
        required
      },
    }
  },
  methods: {
    setValidationTouched(field, value) {
      this.layout[field] = value
      this.$v.layout[field].$touch()
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.layout[name];
      return ($dirty && $error) ? false : null;
    },

    panelFormatter(value) {
      if (value > 20) {
        return 20
      } else if (value < 1) {
        return 1
      } else {
        return value
      }
    },
    colPerPanelFormatter(value) {
      if (value > 2) {
        return 2
      } else if (value < 1) {
        return 1
      } else {
        return value
      }
    },
    recordsPerColumnFormatter(value) {
      if (value > 5) {
        return 5
      } else if (value < 1) {
        return 1
      } else {
        return value
      }
    },
    reset() {
      this.layout = { ...defaultLayout };
    },
    onUpdateCustomize(titleStrip) {
      this.layout.titleStrip = titleStrip
    },
    open(id) {
      this.reset()
      if (id) {
        const n = this.$store.getters.getLayouts.find(layout => layout._id === id)
        this.edit = id
        this.layout.title = n.title
        this.layout.color = n.color
        this.layout.panels = n.panels
        this.layout.colsPerPanel = n.colsPerPanel
        this.layout.recordsPerColumn = n.recordsPerColumn
        this.layout.headings = n.headings
        this.layout.titleStrip = n.titleStrip
      } else {
        this.edit = undefined
      }
      this.$refs['addLayoutModal'].show()
    },
    save() {
      addLayout(this.layout, this.edit)
    },
  },
  computed: {
    placeholder() {
      const currentDate = new Date();
      const currentMonth = currentDate.toLocaleString('en-US', { month: 'long' });
      const currentYear = currentDate.getFullYear();
      return `e.g. Seeburg 100 ${currentMonth} ${currentYear}`
    },
    totalRecords() {
      return this.layout.panels * this.layout.colsPerPanel * this.layout.recordsPerColumn
    }
  },
  components: {
    Card,
    StripDesigner,
  }
};
</script>
<style scoped>
.titlecard {
  justify-self: center;
  transform: scale(0.9);
}

h3 {
  text-align: center;
  background-color: #2ca8c7;
  color: #fff;
  border-radius: 8px;
  padding: 10px;
}

.preview {
  display: grid;
  justify-self: center;
}

.previewGrid {
  display: grid;

}
</style>
